<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Data Kunjungan</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-10" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md-4">
                            <v-select placeholder="Pilih Jenis Faskes" v-model="jnsMonitoring" :options="Config.mr.jenisPelayanan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="inpMonitoring">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button @click="searchMonitoring" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetMonitoring">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwMonitoring">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Diagnosa</th>
                                    <th>Jenis Pelayanan</th>
                                    <th>Kelas Rawat</th>
                                    <th>Nama</th>
                                    <th>No Kartu</th>
                                    <th>No SEP</th>
                                    <th>No Rujukan</th>
                                    <th>Poli</th>
                                    <th>Tgl Pulang SEP</th>
                                    <th>Tgl SEP</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataMonitoring.sep" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>{{v.diagnosa||"-"}}</td>
                                        <td>{{v.jnsPelayanan||"-"}}</td>
                                        <td>{{v.kelasRawat||"-"}}</td>
                                        <td>{{v.nama||"-"}}</td>
                                        <td>{{v.noKartu||"-"}}</td>
                                        <td>{{v.noSep||"-"}}</td>
                                        <td>{{v.noRujukan||"-"}}</td>
                                        <td>{{v.poli||"-"}}</td>
                                        <td>{{v.tglPlgSep||"-"}}</td>
                                        <td>{{v.tglSep||"-"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>
            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Data Klaim</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-10" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md-3">
                            <v-select placeholder="Pilih Jenis Faskes" v-model="jnsKlaim" :options="Config.mr.jenisPelayanan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="inpKlaim">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <v-select placeholder="Pilih Status Klaim" v-model="statusKlaim" :options="Config.mr.statusKlaim" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        
                        <div class="col-md-3">
                            <button @click="searchKlaim" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetKlaim">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwKlaim">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Inacbg</th>
                                    <th>biaya</th>
                                    <th>kelasRawat</th>
                                    <th>noFPK</th>
                                    <th>noSEP</th>
                                    <th>peserta</th>
                                    <th>poli</th>
                                    <th>status</th>
                                    <th>tglPulang</th>
                                    <th>tglSep</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataKlaim.klaim" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>
                                            <span>Kode : {{v.Inacbg.kode||"-"}}</span><br/>
                                            <span>nama : {{v.Inacbg.nama||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>Pengajuan : {{v.biaya.byPengajuan||"-"}}</span><br/>
                                            <span>DiSetujui : {{v.biaya.bySetujui||"-"}}</span><br/>
                                            <span>Tarif Gruper : {{v.biaya.byTarifGruper||"-"}}</span><br/>
                                            <span>Tarif RS : {{v.biaya.byTarifRS||"-"}}</span><br/>
                                            <span>Tarif Topup : {{v.biaya.byTopup||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.kelasRawat||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noFPK||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noSEP||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>Nama : {{v.peserta.nama||"-"}}</span>
                                            <span>No Kartu : {{v.peserta.noKartu||"-"}}</span>
                                            <span>No MR : {{v.peserta.noMR||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.poli||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.status||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglPulang||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglSep||"-"}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Data History</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-10" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md-4">
                            <div class="form-group mb-0">
                            <input v-model="inpDataHistory" class="form-control" 
                            placeholder="Ketik No Kartu" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <date-range-picker
                                    ref="picker"
                                    :locale-data="datePickerConfig.locale"
                                    :autoApply="datePickerConfig.autoApply"
                                    v-model="dateRangeDataHistory"
                                    :opens="'right'"
                                    :ranges="datePickerConfig.ranges"
                                >
                                    <template v-slot:input="picker">
                                        <template v-if="picker.startDate && picker.endDate">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                        <template v-else>
                                            Pilih Tanggal
                                        </template>
                                    </template>
                                </date-range-picker>
                                <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button @click="searchDataHistory" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetDataHistory">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwDataHistory">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>No SEP</th>
                                    <th>Asuransi</th>
                                    <th>Diagnosa</th>
                                    <th>Flag</th>
                                    <th>Jenis pelayanan</th>
                                    <th>Kelas Rawat</th>
                                    <th>Nama Peserta</th>
                                    <th>No Kartu</th>
                                    <th>No Rujukan</th>
                                    <th>Poli</th>
                                    <th>Ppk Pelayanan</th>
                                    <th>Tgl Pulang SEP</th>
                                    <th>Tgl SEP</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataDataHistory.histori" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>{{v.noSep||"-"}}</td>
                                        <td>{{v.asuransi||"-"}}</td>
                                        <td>{{v.diagnosa||"-"}}</td>
                                        <td>{{v.flag||"-"}}</td>
                                        <td>{{v.jnsPelayanan||"-"}}</td>
                                        <td>{{v.kelasRawat||"-"}}</td>
                                        <td>{{v.namaPeserta||"-"}}</td>
                                        <td>{{v.noKartu||"-"}}</td>
                                        <td>{{v.noRujukan||"-"}}</td>
                                        <td>{{v.poli||"-"}}</td>
                                        <td>{{v.ppkPelayanan||"-"}}</td>
                                        <td>{{v.tglPlgSep||"-"}}</td>
                                        <td>{{v.tglSep||"-"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')


export default {
  extends: GlobalVue,
  components: {
    Datepicker,DateRangePicker
  },
  data() {
    return {
        inpMonitoring: null,
        jnsMonitoring: null,
        shwMonitoring: false,  
        dataMonitoring: {},
        
        inpDataHistory: null,
        shwDataHistory: false,  
        dataDataHistory: {},
        dateRangeDataHistory: {
            startDate: null,
            endDate: null,
        },

        datePickerConfig: {
            startDate: null,
            endDate: null,
            autoApply: true,
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        },
        
        inpKlaim: null,
        jnsKlaim: null,
        statusKlaim: null,
        shwKlaim: false,  
        dataKlaim: {},
    }
  },
  
    filters: {
        date(val) {
        return val ? moment(val).format("D MMM YYYY") : ""
        }
    },
  methods: {
    searchMonitoring(){
        if(this.inpMonitoring &&  this.jnsMonitoring){
            this.loadingOverlay = true
            let data = {
                type : "search-monitoring",
                dates : this.inpMonitoring,
                jenis : this.jnsMonitoring
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwMonitoring = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Monitoring Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataMonitoring = resp.response
                this.shwMonitoring = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Monitoring Tidak Ditemukan'
                })
            })
        }
    },
    resetMonitoring(){
        this.inpMonitoring = null
        this.shwMonitoring = false
        this.dataMonitoring = {}
    },
    
    searchDataHistory(){
        if(this.inpDataHistory){
            this.loadingOverlay = true
            let data = {
                type : "search-history-data",
                no_kartu : this.inpDataHistory,
                start_date :  moment(this.dateRangeDataHistory.startDate).format('YYYY-MM-DD'),
                end_date :  moment(this.dateRangeDataHistory.endDate).format('YYYY-MM-DD'),
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwDataHistory = false
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Ada',
                        text: 'Silakan Cari Data Lain'
                    })
                }
                this.loadingOverlay = false
                this.dataDataHistory = resp.response
                console.log(this.dataDataHistory)
                this.shwDataHistory = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    title: 'Data Tidak Ada',
                    text: 'Silakan Cari Data Lain'
                })
            })
        }
    },
    resetDataHistory(){
        this.inpDataHistory = null
        this.shwDataHistory = false
        this.dataDataHistory = {}
        this.dateRangeDataHistory.startDate = null
        this.dateRangeDataHistory.endDate = null
    },

    
    searchKlaim(){
        if(this.inpKlaim){
            this.loadingOverlay = true
            let data = {
                type : "search-data-klaim",
                dates : this.inpKlaim,
                jenis : this.jnsKlaim,
                status : this.statusKlaim,
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwKlaim = false
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Ada',
                        text: 'Silakan Cari Data Lain'
                    })
                }
                this.loadingOverlay = false
                this.dataKlaim = resp.response
                console.log(this.dataKlaim)
                this.shwKlaim = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    title: 'Data Tidak Ada',
                    text: 'Silakan Cari Data Lain'
                })
            })
        }
    },
    resetKlaim(){
        this.inpKlaim = null
        this.shwKlaim = false
        this.dataKlaim = {}
    },
   }
}

</script>

<style lang="scss" style="scoped">
    .vue-daterange-picker{
        width: 220px;
    }
</style>